function header() {
    //Add Class by open Menu
    $('header .navbar-toggler').click(function (event) {
        event.preventDefault();
        $(this).add('html').toggleClass('open-nav');
    });

    $('.mobile-toggle').click(function (event) {
        event.preventDefault();
        $(this).add('.menu-1').toggleClass('open-toggle');
    });

    $( ".head-img .btn-down" ).click(function() {
    var x = $(window).scrollTop();
    $(window).scrollTop(1000);
    });
}
export { header };
