function teaserliste() {
    $('.teaser-liste-wrapper').each(function () {
        var highestBox = 0;
        $('.content-wrapper', this).each(function () {
            if ($(this).height() > highestBox) {
                highestBox = $(this).height();
            }
        });
        $('.content-wrapper', this).height(highestBox);

    });
}

export { teaserliste };