function reviews() {
    $('.review-slider').owlCarousel({
        loop: true,
        margin: 30,
        padding: 40,
        nav: false,
        navText: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 10000,
        autoplaySpeed: 1000,
        navSpeed: 1000,
        items:1,
        responsive:{
            0:{
                nav: false,
            },
            768:{
                nav: false,
            },
            1200:{
                items:1,
                nav: true,
                navText:  [
                '<i class="flaticon-left-arrow" aria-hidden="true"></i>',
                '<i class="flaticon-right-arrow" aria-hidden="true"></i>'
                ],
            }
        }
    });
}

export { reviews };