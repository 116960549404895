function cattoggle() {

    for (let i = 1; i <= 2; i++) {
        $('.main-' + i + ' .flaticon-down-arrow').click(function(event) {
          event.preventDefault();
          $(this).toggleClass('open');
          $(".sub-" + i).toggleClass('open');
        });
    }

}
export { cattoggle };
