function teaserslider() {
  $(".teaserslider").owlCarousel({
    loop: false,
    margin: 30,
    nav: true,
    autoplay: true,
    autoplaySpeed: 1000,
    autoplayTimeout: 3000,
    rewind: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: false,
      },
      1200: {
        items: 3,
        nav: true,
        navText: [
          '<i class="flaticon-left-arrow" aria-hidden="true"></i>',
          '<i class="flaticon-right-arrow" aria-hidden="true"></i>',
        ],
      },
    },
  });

  $(".teaserslider").each(function () {
    var highestBox = 0;
    $(".content-wrapper", this).each(function () {
      if ($(this).height() > highestBox) {
        highestBox = $(this).height();
      }
    });
    $(".content-wrapper", this).height(highestBox);
  });
}

export { teaserslider };
