function team() {
  $(".team-slider").owlCarousel({
    loop: false,
    dots: false,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplaySpeed: 1000,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    rewind: true,

    navText: [
      '<i class="flaticon-left-arrow" aria-hidden="true"></i>',
      '<i class="flaticon-right-arrow" aria-hidden="true"></i>',
    ],
    items: 2,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      768: {
        items: 1,

        margin: 10,
      },
      1200: {
        items: 1,
      },

      1400: {
        items: 2,
        nav: true,
        navText: [
          '<i class="flaticon-left-arrow" aria-hidden="true"></i>',
          '<i class="flaticon-right-arrow" aria-hidden="true"></i>',
        ],
      },
    },
  });
}

export { team };
